<template>
    <div>
        <Head :title="'信息选择'" :isPopup="true" @closePopup="$emit('closePopup')" />
        <div class="con">
            <van-radio-group  v-model="radio">
                <div class="list" v-for="item in arrData" :key="item.id">
                    <van-radio :name="item" class="list-item">
                        <div :style="{marginLeft:'2em'}">车牌号： {{item.carNumberPlate}}</div>
                        <div :style="{marginLeft:'2em'}">车架号： {{item.vin}}</div>
                        <div :style="{marginLeft:'2em'}">品牌： {{item.vinVehicleBrand}}</div>
                        <div :style="{marginLeft:'2em'}">型号： {{item.vinVehicleModel}}</div>
                    </van-radio>
                </div>
            </van-radio-group>
        </div>
        <div class="btn">
            <van-button style="width:150px" @click="submit" type="info">确定</van-button>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
    export default {
        props:['arrData'],
        components: {
            Head,
        },
        data() {
            return {
                radio:"",
            }
        },
        methods: {
            submit() {
                console.log(this.radio)
                this.$emit('cloesIndo',this.radio)
            }
        },
    }
</script>

<style lang="scss" scoped>
.con{
    margin-top: 50px;
    height: 80vh;
    overflow: scroll;
    .list-item{
        padding: 10px;
        border-bottom: 1px solid #eee;
        div{
            line-height: 24px;
        }
    }
}
.btn{
    text-align: center;
    margin-top: 1rem;
}
</style>