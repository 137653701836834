<template lang="">
  <div class="add">
    <div class="header">
      <span @click="previousPage"></span>
      <span>新增临时拆卖件</span>
      <span @click="islogout">退出</span>
    </div>
    <div class="mains">
      <van-field
        v-model="searchValue"
        label-width="110px"
        @blur="blur" 
        >
      <div slot="label" class="flex" >
          <select v-model="searchType">
              <option value="1">自定义编码</option>
              <option value="2">车牌号</option>
              <option value="3">进厂编号</option>
          </select>
      </div>

    </van-field>

      <van-field
        readonly
        clickable
        :value="carAccessoriesName"
        label-width="110px"
        label="拆车件类型："
        placeholder="请选择"
        @click="showSexChang = true"
      >
      </van-field>
      <van-popup v-model="showSexChang" position="bottom">
        <van-picker
          show-toolbar
          value-key="name"
          :columns="carAccessoriesIdList"
          @cancel="showSexChang = false"
          @confirm="onconfirms"
        />
      </van-popup>

      <van-field
        v-model="vehicleBrand"
        label-width="110px"
        label="来源车品牌/型号："
      />

      <van-field
        readonly
        clickable
        :value="treasuryPersonnelName"
        label-width="110px"
        label="库管人员："
        placeholder="请选择"
        @click="showSexChangs = true"
      >
      </van-field>
      <van-popup v-model="showSexChangs" position="bottom">
        <van-picker
          show-toolbar
          value-key="realName"
          :columns="treasuryPersonnelList"
          @cancel="showSexChangs = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <!-- 购买人 -->
      <van-field
        readonly
        clickable
        :value="purchasing_channels"
        label-width="110px"
        label="购买人："
        placeholder="请选择"
        @click="channels = true"
      >
      </van-field>
      <van-popup v-model="channels" position="bottom">
        <van-picker
          show-toolbar
          value-key="shopName"
          :columns="purchasing_channels_idList"
          @cancel="channels = false"
          @confirm="channelsHan"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        :value="salesManName"
        label-width="110px"
        label="销售人员："
        placeholder="请选择"
        @click="showSexChang1 = true"
      >
      </van-field>
      <van-popup v-model="showSexChang1" position="bottom">
        <van-picker
          show-toolbar
          value-key="realName"
          :columns="salesManList"
          @cancel="showSexChang1 = false"
          @confirm="onconfirms1"
        />
      </van-popup>
      <van-field
        v-model="saleprice"
        label-width="110px"
        label="销售金额/元："
        placeholder="请输入销售金额"
      />
      <van-field name="radio" label="单选框">
        <template #input>
          <van-radio-group v-model="five_big" direction="horizontal">
            <van-radio :name="1">包含</van-radio>
            <van-radio :name="2">不包含</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-cell title="选择年月日" :value="date" @click="show = true" />
      <van-calendar
        v-model="show"
        :show-confirm="false"
        @confirm="onConfirmDate"
      />
      <!-- <van-field readonly clickable :value="dateChanged" label-width="110px" label="销售时间：" placeholder="请选择" @click="Tame = true"  > </van-field> -->
      <van-popup v-model="Tame" position="bottom">
        <!-- <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择年月日"
                @confirm="confirmHan"
                /> -->
        <van-datetime-picker
          v-model="currentDate"
          type="time"
          title="选择时间"
          @confirm="confirmHan"
        />
      </van-popup>
      <van-field
        v-model="num"
        label-width="110px"
        label="数量："
        placeholder="请输入数量"
      />
      <van-field
        v-model="weight"
        label-width="110px"
        label="重量："
        placeholder="请输入重量"
      />
      <van-field
        v-model="color"
        label-width="110px"
        label="颜色："
        placeholder="请输入颜色"
      />
      <van-field
        v-model="patrsName"
        label-width="110px"
        label="拆车件名称："
        placeholder="请输入拆车件名称"
      />

      <div v-if="!isWechat">
        <van-field name="uploader" label="图片上传">
          <template #input>
            <van-uploader
              v-model="imageFile"
              :after-read="uploadershang('restsImg')"
              :before-read="beforeRead"
              multiple
              :before-delete="deleteimg2"
            ></van-uploader>
          </template>
        </van-field>
      </div>

      <div class="upimg" v-else>
        <p>图片上传</p>
        <p>
          <span class="imgbox" v-for="(item, index) in WXimg" :key="index">
            <van-image
              class="vanImage"
              width="80"
              height="80"
              :src="item"
              @click="bigImg(item)"
            >
              <van-icon
                class="iconX"
                name="close"
                @click="delImgHan('arrPhoto', index)"
              />
            </van-image>
          </span>
          <span class="uploaderImg" @click="WeChooseImage(9)">
            <van-icon class="iconz" name="plus" />
          </span>
        </p>
      </div>

      <van-field
        v-model="remark"
        rows="2"
        type="textarea"
        label-width="110px"
        label="备注："
      />

      <div class="btn_boxs" @click="save()">保存并复制到零件销售库</div>
    </div>
	<van-popup v-model="Arrpopup"  :closeable="false" :close-on-click-overlay="false"  position="bottom"  :style="{  height: '100%',  }" >
		<radiolist :arrData="arrData" @closePopup="closePopup" @cloesIndo="cloesIndo" />
    </van-popup>
  </div>
</template>
<script>
import {Toast} from 'vant'
import { deleteCookie } from "../../utils/env";
import { encipherMent, encryptDecode } from "@/utils/encrypt";
import radiolist from './components/radiolist.vue'
export default {
	components: {
		radiolist,
	},
  data() {
    return {
		end_of_lifeVehiclesId: "", //来源车厂编号
		carAccessoriesId: "", //拆车件类型
		carAccessoriesName: "", //拆车件类型
		carAccessoriesIdList: "", //拆车件类型列表
		treasuryPersonnel: "", //库管人员
		treasuryPersonnelName: "", //库管人员
		treasuryPersonnelList: [], //库管人员列表
		salesMan: "", //销售人员
		salesManName: "", //销售人员
		salesManList: [], //销售人员列表
		saleprice: "", //销售金额
		saleDate: "", //销售时间
		remark: "", //备注
		num: "", //数量
		weight: "", //数量
		color: "", //颜色
		vehicleBrand: "", //车品牌
		vehicleModel: "", //车品牌id
		patrsName: "", //拆车件名称
		idStatus: "1", //进场编号状态
		showSexChang1: false,
		showSexChang: false,
		showSexChangs: false,
		five_big: 2,
		currentDate: "",
		dateChanged: "",
		show: false,
		date: "",
		Tame: false,
		channels: false,
		purchasing_channels_idList: [],
		purchasing_channels_id: "",
		purchasing_channels: "",
		wximagelocalids: [], //判断上传张数
		toast: {},
		imageFile: [],//其他照片
		imageArr: [],//其他照片
		WXimg: [],//其他照片
		searchType:"1",//类型type
		searchValue:"",
		arrData:'',
		Arrpopup:false,
    };
  },
  created() {
    //拆车类型
    this.$http
      .post("/index.php/index/Simply", { type: "carAccessories" })
      .then((res) => {
        if (res.data.code == 0) {
          //console.log(res);
          this.carAccessoriesIdList = res.data.data;
        } else if (res.data.code == "-1") {
          this.$toast.fail(res.data.msg);
          deleteCookie("token");
          this.$router.push("/login");
          // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
          //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{

          //       if(res.data.code == 0){
          //           let domain = this.zhuan(res.data.data)
          //           let url = window.location.origin

          //           window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
          //       }
          //   })
        }
      });
    //库管人员
    this.$http
      .post("/index.php/index/user/userList", { type: "treasuryPersonnel" })
      .then((res) => {
        if (res.data.code == 0) {
          //console.log(res);
          this.treasuryPersonnelList = res.data.data;
        } else if (res.data.code == "-1") {
          this.$toast.fail(res.data.msg);
          this.$router.push("/login");
          deleteCookie("token");
          // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
          //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{

          //       if(res.data.code == 0){
          //           let domain = this.zhuan(res.data.data)
          //           let url = window.location.origin

          //           window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
          //       }
          //   })
        }
      });
    //销售人员
    this.$http
      .post("/index.php/index/user/userList", { type: "salesMan" })
      .then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          //console.log(res);
          this.salesManList = res.data.data;
        } else if (res.data.code == "-1") {
          this.$toast.fail(res.data.msg);
          this.$router.push("/login");
          deleteCookie("token");
          // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
          //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{

          //       if(res.data.code == 0){
          //           let domain = this.zhuan(res.data.data)
          //           let url = window.location.origin

          //           window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
          //       }
          //   })
        }
      });
    //销售人员
    this.$http
      .post("index.php/index/Purchasing_Channels/purchasingChannelsList2")
      .then((res) => {
        if (res.data.code == 0) {
          console.log(res);
          this.purchasing_channels_idList = res.data.data;
        }
      });
  },
  mounted() {
    if (this.isWechat) {
      this.wcConfig();
    }
  },
  watch: {
    wximagelocalids: {
      handler(newValue, oldValue) {
        if (newValue.length > 0) {
          this.toast = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "上传中...",
          });
          this.wxUpload(newValue[0]);
        } else {
          this.toast.clear();
        }
      },
      deep: true,
    },
  },
  methods: {
	  // 上传照片
	  uploadershang(i){
			return file =>{
					file.status = "uploading";
					file.message = "上传中...";
				if(file instanceof Array){
					file.forEach(item=>{
						this.upImages(i,item)
					})
				}else{
					this.upImages(i,file)
				}
			}
           },
		upImages(i,file){
            file.status = "uploading";
            file.message = "上传中...";
            let params = new FormData(); //创建form对象
            params.append("file", file.file);
            this.$http.post("/index.php/index/upload/index", params)
            .then((res) => {
                // toast.clear()//清除加载效果
            if (res.data.code == 0) {
				console.log(res,i)
                file.status = "done";
                    if(i=='restsImg'){
						
                        this.WXimg.push(res.data.data)
                    }
                } else if(res.data.code == -1){
                    deleteCookie('token')
                    this.$toast.fail(res.data.msg)
                    this.$router.push('/')
                }else{
                    file.status = "failed";
                   file.message = "上传失败";
                    this.$toast.fail(res.data.msg)
                }
            }).catch((e)=>{
               if(i=='restsImg'){
                    this.imageFile = []
                }
                // toast.clear()//清除加载效果
                file.status = "failed";
                file.message = "上传失败";
                this.$toast.fail('网络开小差了，请稍后重试！')
            })
           },
        
		beforeRead(file){
			console.log(file)
			if(file instanceof Array){
				for(let i=0;i<file.length;i++){
					if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
						Toast('请上传 jpg/png 格式图片');
						return false;
					}
					return true;
				}
			}else{
				if((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
					Toast('请上传 jpg/png 格式图片');
					return false;
				}
				return true;
			}
	},
	deleteimg2(file,datail){
		this.imageFile.forEach((item,index)=>{
			if(index == datail.index){   // 判断删除的下标是否正确
				this.imageFile.splice(index,1) // 使用遍历找到的index去数组里面删除
			}
		})
		this.WXimg.forEach((item,index)=>{
			if(index == datail.index){   // 判断删除的下标console.log(this.WXimg)
				this.WXimg.splice(index,1) // 使用遍历找到的index去数组里面删除
			}
		})
		this.imageArr.forEach((item,index)=>{
			
			if(index == datail.index){   // 判断删除的下标是否正确
				this.imageArr.splice(index,1) // 使用遍历找到的index去数组里面删除
			}
		})
	},
    zhuan(url) {
      let str = url;
      let str1 = str.replace("https", "");
      str1 = str1.replace("http", "");
      let str2 = "https" + str1;
      return str2;
    },
    //选中日期
    onConfirmDate(date) {
      // this.show = false;
      this.Tame = true;
      // this.date = this.formatDate(date);
      this.date = this.$moment(date).format("YYYY-MM-DD");
      //console.log(this.date )
    },
    // dateChanged($event) {
    //   //console.log(this.saleDate);
    //   // this.$emit("dateChanged", $event); //将值放在自定义的事件函数中作为参数
    // },
    channelsHan(e) {
      console.log(e);
      this.channels = false;
      this.purchasing_channels_id = e.id;
      this.purchasing_channels = e.shopName;
    },
    //类型

    onconfirms(e) {
      this.showSexChang = false;
      this.carAccessoriesId = e.id;
      this.carAccessoriesName = e.name;
      //console.log(e)
    },
    //库管
    onConfirm(e) {
      this.showSexChangs = false;
      this.treasuryPersonnel = e.id;
      this.treasuryPersonnelName = e.realName;
      //console.log(e)
    },
    //销售
    onconfirms1(e) {
      this.showSexChang1 = false;
      this.salesManName = e.realName;
      this.salesMan = e.id;
    },
    //时间
    confirmHan(e) {
      this.dateChanged = this.date + " " + e + ":00";
      this.Tame = false;
      this.show = false;
      //console.log(this.dateChanged)
    },
    save() {
        let obj = {
          end_of_lifeVehiclesId: this.end_of_lifeVehiclesId,
          carAccessoriesId: this.carAccessoriesId, //拆车类型
          saveUserId: this.treasuryPersonnel,
          saleUserId: this.salesMan,
          saleprice: this.saleprice,
          saleDate: this.dateChanged,
          remark: this.remark,
          num: this.num,
          weight: this.weight,
          color: this.color,
          patrsName: this.patrsName,
          saveType: "2",
          five_big: this.five_big,
          purchasing_channels_id: this.purchasing_channels_id, // 购买人
		  image:this.WXimg,
        };
        let data = encipherMent(JSON.stringify(obj));
        let param = new URLSearchParams();
        param.append("value", data);
        this.$http
          .post("/index.php/index/Dismantled_Car_Parts/temporarily", param)
          .then((res) => {
            let result = {};
            if (res.data.code == -1 || res.data.code == 9) {
              result = res.data;
            } else {
              result = JSON.parse(encryptDecode(res.data));
            }
            console.log(result);
            if (result.code == "0") {
              this.$toast.success("提交成功");
              this.$router.go("-1");
            } else {
              this.$toast.fail(result.msg);
            }
          });
      
    },
    previousPage() {
      this.$router.go(-1);
    }, 
	//关闭弹窗
	closePopup(){
		this.Arrpopup=false
	},
	//选择的信息
	cloesIndo(e){
		this.vehicleBrand = e.vinVehicleBrand
		this.vehicleModel = e.vinVehicleModel
		this.end_of_lifeVehiclesId = e.id
		this.Arrpopup=false
	},
    blur() {
     
      this.$http.post('/index.php/filter/vehiclesInfo',{searchValue:this.searchValue,searchType:this.searchType}).then(res=>{
        console.log(res.data.data.length)
        if(res.data.code == 0){
          if( res.data.data.length ==1){
				this.vehicleBrand = res.data.data[0].vinVehicleBrand;
				this.vehicleModel = res.data.data[0].vinVehicleModel;
				this.end_of_lifeVehiclesId = res.data.data[0].id
          }else if( res.data.data.length !=0){
            	this.arrData = res.data.data
				this.Arrpopup = true
          }
          
        }else{
          this.$toast.fail(res.data.msg);
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.add {
  .header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/u39.png);
      background-size: 100%;
      margin-top: 8px;
    }
  }

  .mains {
    width: 90%;
    margin: 1.5rem auto;
    font-size: 0.35rem;
    // div {
    //   margin-top: 10px;
    //   span {
    //     width: 3.5rem;
    //     display: inline-block;
    //     text-align: center;
    //     margin-right: 23px;
    //   }
    //   input {
    //     width: 50%;
    //     height: 0.5rem;
    //   }
    // }
  }
  .btn_boxs {
    margin: 2rem auto;
    width: 60%;
    text-align: center;
    height: 0.8rem;
    background-color: cadetblue;
    color: white;
    line-height: 0.8rem;
    font-size: 0.4rem;
    border-radius: 5px;
  }
}
</style>
